import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Button, Flex, Form, Heading, Text, TextField, Well } from '@adobe/react-spectrum';
import WizardStepActionBar from '../WizardStepActionBar';
import { useDocumentServices } from '../../../contexts/DocumentServicesApiContext';
import { useForm, Controller } from 'react-hook-form';
import ListContainer from '../../SignatureRequestSignerListEditor/ListContainer';

interface AddSignersStepProps {
  signatureRequestHashId: string | undefined;
}

const AddSignersStep: FunctionComponent<AddSignersStepProps> = ({ signatureRequestHashId }) => {
  const { signaturesApi } = useDocumentServices();

  const [signersList, setSignersList] = useState<
    Array<{
      signerHashId: string;
      signerName: string;
      signerOrder: number;
    }>
  >([]);

  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      signerName: '',
      signerEmail: '',
      signerPhone: '',
    },
  });

  const fetchSignatureRequestSigners = useCallback(
    async (signatureRequestHashId: string) => {
      let signatureRequestSignersResponse = await signaturesApi.signatureRequestSignersGetList(signatureRequestHashId);

      setSignersList(
        signatureRequestSignersResponse.data.items.map((value) => {
          return {
            signerHashId: value.hashId,
            signerName: value.signerName,
            signerOrder: 1,
          };
        }),
      );
    },
    [signaturesApi],
  );

  const onSubmit = async (data: any) => {
    console.log(data);

    try {
      if (!signatureRequestHashId) {
        return;
      }

      await signaturesApi.signatureRequestSignersCreate(signatureRequestHashId, {
        signerExtUserId: '',
        signerName: data.signerName,
        signerEmail: data.signerEmail,
        signerPhone: '',
        signerOrder: 0,
      });

      await fetchSignatureRequestSigners(signatureRequestHashId);
    } catch (e) {}
  };

  const handleMoveItem = async (
    signerHashId: string,
    signerOrder: number,
    items: Array<{ signerHashId: string; signerOrder: number }>,
  ) => {
    if (signatureRequestHashId) {
      await signaturesApi.signatureRequestSignersUpdateOrder(signatureRequestHashId, {
        signersOrder: items.reduce(
          (accumulator, current) => ({ ...accumulator, [current.signerHashId]: current.signerOrder }),
          {},
        ),
      });
    }
  };

  const handleRemoveItem = async (signerHashId: string) => {
    if (signatureRequestHashId) {
      await signaturesApi.signatureRequestSignersDelete(signatureRequestHashId, signerHashId);
      await fetchSignatureRequestSigners(signatureRequestHashId);
    }
  };

  useEffect(() => {
    if (signatureRequestHashId) {
      fetchSignatureRequestSigners(signatureRequestHashId);
    }
  }, [fetchSignatureRequestSigners, signatureRequestHashId, signaturesApi]);

  return (
    <>
      <Heading level={3}>Who needs to sign?</Heading>
      <Well>
        <Form maxWidth="size-3600" isRequired necessityIndicator="label" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="signerName"
            control={control}
            rules={{ required: true, minLength: 2, maxLength: 255 }}
            render={({ field }) => (
              <TextField
                label="Name"
                placeholder="Jane Smith"
                description="Name of the signer"
                validationState={
                  (formState.errors?.signerName && 'invalid') || (formState.isValid && 'valid') || undefined
                }
                errorMessage={
                  (formState.errors?.signerName?.type === 'required' && 'This is required') ||
                  (formState.errors?.signerName?.type === 'minLength' && 'Length must be between 2 and 255 chars')
                }
                {...field}
              />
            )}
          />
          <Controller
            name="signerEmail"
            control={control}
            rules={{
              required: true,
              minLength: 3,
              maxLength: 255,
            }}
            render={({ field, fieldState }) => (
              <TextField
                label="Email"
                placeholder="abc@adobe.com"
                description="Email of the signer"
                validationState={
                  (fieldState.invalid && 'invalid') ||
                  (formState.isValid && fieldState.isDirty && !fieldState.invalid && 'valid') ||
                  undefined
                }
                errorMessage={
                  (fieldState.error?.type === 'required' && 'This is required') ||
                  (fieldState.error?.type === 'minLength' && 'Length must be between 3 and 255 chars')
                }
                {...field}
              />
            )}
          />

          <Button variant="primary" type="submit">
            Add signer
          </Button>
        </Form>
      </Well>

      <Heading level={3}>Signers</Heading>
      <Text>Re-order who signs first</Text>

      <Flex direction="column" gap="size-200" marginTop="size-400" marginBottom="size-400" maxWidth="size-1000">
        <ListContainer items={signersList} onMoveItem={handleMoveItem} onRemoveItem={handleRemoveItem} />
      </Flex>

      <WizardStepActionBar />
    </>
  );
};

AddSignersStep.defaultProps = {};

export default AddSignersStep;
