import { FunctionComponent } from 'react';
import '@spectrum-css/steplist/dist/index-vars.css';
import { Button, ButtonGroup, Divider, Flex, View } from '@adobe/react-spectrum';
import { StepWizardChildProps } from 'react-step-wizard';
import { useWizard } from 'react-use-wizard';

const WizardStepActionBar: FunctionComponent<StepWizardChildProps | any> = (props) => {
  const wizard = useWizard();

  return (
    <View>
      <Divider marginTop="size-200" marginBottom="size-150" />
      <Flex
        direction="row"
        justifyContent={{
          base: 'center',
          M: 'right',
        }}
        gap="size-300"
      >
        <ButtonGroup>
          {!wizard.isFirstStep && (
            <Button variant="secondary" onPress={wizard.previousStep}>
              Go back
            </Button>
          )}

          <Button variant="cta" onPress={wizard.nextStep}>
            Continue
          </Button>
        </ButtonGroup>
      </Flex>
    </View>
  );
};

WizardStepActionBar.defaultProps = {};

export default WizardStepActionBar;
