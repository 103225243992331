import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { ListItemTypes } from './ListItemTypes';
import ListItem from './ListItem';
import update from 'immutability-helper';
import '@spectrum-css/assetlist/dist/index-vars.css';

interface ListItemType {
  signerHashId: string;
  signerName: string;
  signerOrder: number;
}

interface ListProps {
  items: Array<ListItemType>;

  onMoveItem?: (signerHashId: string, signerOrder: number, items: Array<ListItemType>) => void;
  onRemoveItem?: (signerHashId: string) => void;
}

const List: FunctionComponent<ListProps> = (props) => {
  const [items, setItems] = useState(props.items);

  useEffect(() => {
    setItems(props.items);
  }, [props.items]);

  const findItem = useCallback(
    (id: string) => {
      const item = items.filter((i) => `${i.signerHashId}` === id)[0];
      return {
        item,
        index: items.indexOf(item),
      };
    },
    [items],
  );

  const moveItem = useCallback(
    (id: string, atIndex: number) => {
      const { item, index } = findItem(id);

      let newItems = update(items, {
        $splice: [
          [index, 1],
          [atIndex, 0, item],
        ],
      });

      newItems.map((value, index) => {
        value.signerOrder = index;
        return value;
      });

      setItems(newItems);

      if (props.onMoveItem) {
        props.onMoveItem(id, atIndex, newItems);
      }
    },
    [findItem, items, props],
  );

  const removeItem = useCallback(
    (signerHashId: string) => {
      if (props.onRemoveItem) {
        props.onRemoveItem(signerHashId);
      }
    },
    [props],
  );

  const [, drop] = useDrop(() => ({ accept: ListItemTypes.DOCUMENT }));

  return (
    <ul className="spectrum-AssetList" ref={drop}>
      {items.map((item) => (
        <ListItem
          key={item.signerHashId}
          signerHashId={`${item.signerHashId}`}
          signerName={item.signerName}
          signerOrder={item.signerOrder}
          moveItem={moveItem}
          findItem={findItem}
          removeItem={removeItem}
        />
      ))}
    </ul>
  );
};

List.defaultProps = {};

export default List;
