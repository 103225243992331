import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Heading,
  Text,
  Link,
  Well,
  Checkbox,
  useDialogContainer,
} from '@adobe/react-spectrum';
import { FunctionComponent, useState } from 'react';

export const SignatureESignDisclosureAndConsentDialog: FunctionComponent<{
  onCancelled?: () => void;
  onConsented?: () => void;
}> = ({ onCancelled, onConsented }) => {
  const dialog = useDialogContainer();
  const [agreed, setAgreed] = useState<boolean>(false);

  return (
    <Dialog size="M">
      <Heading>E-Sign Disclosure & Consent</Heading>
      <Divider />
      <Content>
        <Text>
          Please read the{' '}
          <Link>
            <a href="https://example.com">Electronic Record and Signature Disclosure</a>
          </Link>
          .
        </Text>
        <Well marginTop="size-200">
          <Checkbox isSelected={agreed} onChange={setAgreed} autoFocus>
            I agree to use electronic records and signatures
          </Checkbox>
        </Well>
      </Content>
      <ButtonGroup>
        <Button
          variant="secondary"
          onPress={() => {
            if (onCancelled) {
              onCancelled();
            }

            dialog.dismiss();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="cta"
          onPress={() => {
            if (onConsented) {
              onConsented();
            }

            dialog.dismiss();
          }}
          isDisabled={!agreed}
        >
          Continue
        </Button>
      </ButtonGroup>
    </Dialog>
  );
};

SignatureESignDisclosureAndConsentDialog.defaultProps = {};
