import { FunctionComponent, useState } from 'react';
import { Wizard } from 'react-use-wizard';
import WizardStepList from './WizardStepList';
import SelectFilesStep from './Steps/SelectFilesStep';
import AddSignersStep from './Steps/AddSignersStep';
import AddFieldsStep from './Steps/AddFieldsStep';
import SendStep from './Steps/SendStep';
import { CreateSignatureRequestResponse } from '@zymlabs/document-services-axios-sdk';
import { useNavigate, useParams } from 'react-router-dom';

interface SignatureRequestAddWizardProps {}

const SignatureRequestAddWizard: FunctionComponent<SignatureRequestAddWizardProps> = () => {
  const params = useParams();

  const [signatureRequestHashId, setSignatureRequestHashId] = useState<undefined | string>(
    params.signatureRequestHashId,
  );
  const navigate = useNavigate();

  const handleSelectFilesStepSignatureRequestCreated = (signatureRequest: CreateSignatureRequestResponse) => {
    setSignatureRequestHashId(signatureRequest.hashId);
    navigate('/signature-requests/' + signatureRequest.hashId + '/edit', { replace: true });
  };

  return (
    <Wizard header={<WizardStepList />}>
      <SelectFilesStep
        signatureRequestHashId={signatureRequestHashId}
        onSignatureRequestCreated={handleSelectFilesStepSignatureRequestCreated}
      />
      <AddSignersStep signatureRequestHashId={signatureRequestHashId} />
      <AddFieldsStep signatureRequestHashId={signatureRequestHashId} />
      <SendStep signatureRequestHashId={signatureRequestHashId} />
    </Wizard>
  );
};

SignatureRequestAddWizard.defaultProps = {};

export default SignatureRequestAddWizard;
