import { FunctionComponent } from 'react';
import '@spectrum-css/steplist/dist/index-vars.css';
import { StepWizardChildProps } from 'react-step-wizard';
import { useWizard } from 'react-use-wizard';

const WizardStepList: FunctionComponent<StepWizardChildProps | any> = (props) => {
  console.log('test', props);
  const wizard = useWizard();

  return (
    <div className="spectrum-Steplist spectrum-Steplist--interactive" role="list" style={{ height: '40px' }}>
      <div
        className={`spectrum-Steplist-item ${wizard.activeStep > 0 ? 'is-complete' : ''} ${
          wizard.activeStep === 0 ? 'is-selected' : ''
        }`}
        role="listitem"
        aria-posinset={1}
        aria-setsize={4}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className={`spectrum-Steplist-link ${wizard.activeStep > 0 ? 'is-complete' : ''}`}
          role="link"
          tabIndex={-1}
          onClick={() => wizard.goToStep(0)}
        >
          <span className="spectrum-Steplist-label">Documents</span>
          <span className="spectrum-Steplist-markerContainer">
            <span className="spectrum-Steplist-marker"></span>
          </span>
        </a>
        <span className="spectrum-Steplist-segment"></span>
      </div>
      <div
        className={`spectrum-Steplist-item ${wizard.activeStep > 1 ? 'is-complete' : ''} ${
          wizard.activeStep === 1 ? 'is-selected' : ''
        }`}
        role="listitem"
        aria-posinset={2}
        aria-setsize={4}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="spectrum-Steplist-link is-complete" role="link" tabIndex={-1} onClick={() => wizard.goToStep(1)}>
          <span className="spectrum-Steplist-label">Signers</span>
          <span className="spectrum-Steplist-markerContainer">
            <span className="spectrum-Steplist-marker"></span>
          </span>
        </a>
        <span className="spectrum-Steplist-segment"></span>
      </div>
      <div
        className={`spectrum-Steplist-item ${wizard.activeStep > 2 ? 'is-complete' : ''} ${
          wizard.activeStep === 2 ? 'is-selected' : ''
        }`}
        role="listitem"
        aria-posinset={3}
        aria-setsize={4}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className="spectrum-Steplist-link is-selected"
          role="link"
          aria-current="step"
          tabIndex={-1}
          onClick={() => wizard.goToStep(2)}
        >
          <span className="spectrum-Steplist-label">Place fields</span>
          <span className="spectrum-Steplist-markerContainer">
            <span className="spectrum-Steplist-marker"></span>
          </span>
        </a>
        <span className="spectrum-Steplist-segment"></span>
      </div>
      <div
        className={`spectrum-Steplist-item ${wizard.activeStep > 3 ? 'is-complete' : ''} ${
          wizard.activeStep === 3 ? 'is-selected' : ''
        }`}
        role="listitem"
        aria-posinset={4}
        aria-setsize={4}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="spectrum-Steplist-link" role="link" tabIndex={-1} onClick={() => wizard.goToStep(3)}>
          <span className="spectrum-Steplist-label">Review & Send</span>
          <span className="spectrum-Steplist-markerContainer">
            <span className="spectrum-Steplist-marker"></span>
          </span>
        </a>
        <span className="spectrum-Steplist-segment"></span>
      </div>
    </div>
  );
};

WizardStepList.defaultProps = {};

export default WizardStepList;
