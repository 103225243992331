import { FunctionComponent } from 'react';
import SignatureRequestAddWizard from '../../components/SignatureRequestAddWizard/SignatureRequestAddWizard';
import { View } from '@adobe/react-spectrum';

export const SignatureRequestsNewPage: FunctionComponent = () => {
  return (
    <View margin="size-300">
      <SignatureRequestAddWizard />
    </View>
  );
};
