import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Content, Flex, Heading, IllustratedMessage, ProgressBar, Text } from '@adobe/react-spectrum';
import Upload from '@spectrum-icons/illustrations/Upload';
import { wrapWc } from 'wc-react';
import '@spectrum-css/assetlist/dist/index-vars.css';
import '@spectrum-css/icon/dist/index-vars.css';
import '@spectrum-web-components/dropzone/sp-dropzone.js';
import WizardStepActionBar from '../WizardStepActionBar';
import ListContainer from '../../SignatureRequestDocumentListEditor/ListContainer';
import { useDocumentServices } from '../../../contexts/DocumentServicesApiContext';
import {
  CreateSignatureRequestResponse,
  GetSignatureRequestDetailResponse,
} from '@zymlabs/document-services-axios-sdk';

const SpDropzone = wrapWc('sp-dropzone');

interface CurrentUploadProgress {
  fileName: string;
  percentCompleted: number;
}

interface SelectFilesStepProps {
  signatureRequestHashId: string | undefined;
  onSignatureRequestCreated: (signatureRequest: CreateSignatureRequestResponse) => void;
}

const SelectFilesStep: FunctionComponent<SelectFilesStepProps> = ({
  signatureRequestHashId,
  onSignatureRequestCreated,
}) => {
  const { signaturesApi } = useDocumentServices();

  const [currentUploads, setCurrentUploads] = useState<CurrentUploadProgress[]>([]);
  const [uploadedFilesList, setUploadedFilesList] = useState<
    Array<{
      documentHashId: string;
      fileName: string;
      documentOrder: number;
    }>
  >([]);

  const [signatureRequest, setSignatureRequest] = useState<
    CreateSignatureRequestResponse | GetSignatureRequestDetailResponse
  >();

  const handleDropzoneDrop = async (event: CustomEvent) => {
    // Stops some browsers from redirecting.
    event.stopPropagation();
    event.preventDefault();

    // HACK: Due to this event handler being async, if we don't store the file list then it is empty on the first file
    // drop, things for some reason start working on the 2nd file drop. So the workaround here was to access and copy
    // the file list first before doing anything else.
    let fileListArray: File[] = Array.from(event.detail.dataTransfer.files);

    // Create the signature request if it doesn't exist
    let sr = signatureRequest;

    if (sr === undefined) {
      try {
        if (signatureRequestHashId) {
          let srd = await signaturesApi.signatureRequestsGetDetail(signatureRequestHashId);
          setSignatureRequest(srd.data);
          sr = srd.data;
        } else {
          let srr = await signaturesApi.signatureRequestsCreate({
            requestTitle: 'Draft',
          });

          setSignatureRequest(srr.data);
          sr = srr.data;
        }
      } catch (e) {
        alert('failure');
      }
    }

    if (sr === undefined) {
      console.log('undefined sr');
      return;
    }

    onSignatureRequestCreated(sr);

    for (const file of fileListArray) {
      console.log(file);

      try {
        await signaturesApi.signatureRequestDocumentsCreate(sr.hashId, file.name, undefined, file, {
          onUploadProgress: (progressEvent: ProgressEvent) => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log(progressEvent);
            console.log(percentCompleted);

            setCurrentUploads([
              {
                fileName: file.name,
                percentCompleted,
              },
            ]);
          },
        });
      } catch (e: unknown) {
        if (typeof e === 'string') {
          alert(e);
        } else if (e instanceof Error) {
          alert(e.message);
        }
      }
    }

    setCurrentUploads([]);

    let signatureRequestDocumentsResponse = await signaturesApi.signatureRequestDocumentsGetList(sr.hashId);

    setUploadedFilesList(
      signatureRequestDocumentsResponse.data.items.map((value) => {
        return {
          documentHashId: value.hashId,
          fileName: value.documentName,
          documentOrder: 1,
        };
      }),
    );
  };

  const fetchSignatureRequestDocuments = useCallback(
    async (signatureRequestHashId: string) => {
      let signatureRequestDocumentsResponse = await signaturesApi.signatureRequestDocumentsGetList(
        signatureRequestHashId,
      );

      setUploadedFilesList(
        signatureRequestDocumentsResponse.data.items.map((value) => {
          return {
            documentHashId: value.hashId,
            fileName: value.documentName,
            documentOrder: 1,
          };
        }),
      );
    },
    [signaturesApi],
  );

  useEffect(() => {
    if (signatureRequestHashId) {
      fetchSignatureRequestDocuments(signatureRequestHashId);
    }
  }, [fetchSignatureRequestDocuments, signatureRequestHashId, signaturesApi]);

  const handleMoveFile = async (
    documentHashId: string,
    documentOrder: number,
    items: Array<{ documentHashId: string; documentOrder: number }>,
  ) => {
    if (signatureRequestHashId) {
      await signaturesApi.signatureRequestDocumentsUpdateOrder(signatureRequestHashId, {
        documentsOrder: items.reduce(
          (accumulator, current) => ({ ...accumulator, [current.documentHashId]: current.documentOrder }),
          {},
        ),
      });
    }
  };

  const handleRemoveFile = async (documentHashId: string) => {
    if (signatureRequestHashId) {
      await signaturesApi.signatureRequestDocumentsDelete(signatureRequestHashId, documentHashId);
      await fetchSignatureRequestDocuments(signatureRequestHashId);
    }
  };

  return (
    <>
      <Heading level={2}>What documents needs signatures?</Heading>

      <Heading level={3}>Select your documents</Heading>
      {/* eslint-disable-next-line react/style-prop-object */}
      <SpDropzone
        id="dropzone-1"
        tabindex="0"
        /* eslint-disable-next-line react/style-prop-object */
        style="height: 250px"
        // sp-dropzone-should-accept={(e: any) => console.log(e.detail.dataTransfer.files)}
        dropEffect="copy"
        sp-dropzone-drop={handleDropzoneDrop}
      >
        <IllustratedMessage>
          <Upload aria-label="Upload icon" />
          <Heading>Drag and Drop your file</Heading>
          <Content>
            Select a File from your computer
            <br /> or select a File from your cloud drive
          </Content>
        </IllustratedMessage>
      </SpDropzone>

      <Heading level={3}>Documents you've provided</Heading>
      <Text>Re-order your documents</Text>

      {currentUploads.length > 0 && (
        <Flex direction="column" gap="size-200" marginTop="size-400" marginBottom="size-400">
          {currentUploads.map((c) => (
            <ProgressBar label={c.fileName} width="100%" value={c.percentCompleted} />
          ))}
        </Flex>
      )}

      <Flex direction="column" gap="size-200" marginTop="size-400" marginBottom="size-400" maxWidth="size-1000">
        <ListContainer items={uploadedFilesList} onMoveItem={handleMoveFile} onRemoveItem={handleRemoveFile} />
      </Flex>

      <WizardStepActionBar />
    </>
  );
};

SelectFilesStep.defaultProps = {};

export default SelectFilesStep;
