import React from 'react';
import Keycloak from 'keycloak-js';
import { AuthClientTokens } from '@react-keycloak/core';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import env from '@beam-australia/react-env';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { LoadingScreen } from './components/LoadingScreen';
import Routes from './routes/Routes';
import DocumentServicesApiProvider from './contexts/DocumentServicesApiContext';
import { SpectrumProvider } from './contexts/SpectrumProviderContext';

function App() {
  const { setToken } = useAuth();

  const keycloak = Keycloak({
    url: env('KEYCLOAK_URL'),
    realm: env('KEYCLOAK_REALM'),
    clientId: env('KEYCLOAK_CLIENT_ID'),
  });

  const onToken = (tokens: AuthClientTokens) => {
    if (tokens.token) {
      setToken(tokens.token);
    }
  };

  // @ts-ignore
  const checkLoginIframe = !window.Cypress;

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: 'login-required',
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        checkLoginIframe,
      }}
      autoRefreshToken={true}
      onTokens={onToken}
      onEvent={(event, error) => {
        console.log('onKeycloakEvent', event, error);
      }}
      LoadingComponent={<LoadingScreen message="Authenticating..." />}
    >
      <AuthProvider>
        <DocumentServicesApiProvider>
          <SpectrumProvider>
            <Routes />
          </SpectrumProvider>
        </DocumentServicesApiProvider>
      </AuthProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
