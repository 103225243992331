import {
  ActionButton,
  Button,
  ButtonGroup,
  Content,
  Header,
  Dialog,
  Divider,
  Flex,
  Heading,
  Text,
  Form,
  TextField,
  ActionGroup,
  Menu,
  MenuTrigger,
  Item,
  Tabs,
  TabList,
  TabPanels,
  IllustratedMessage,
  Well,
  View,
  useDialogContainer,
  Image,
} from '@adobe/react-spectrum';
import { FunctionComponent, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import Upload from '@spectrum-icons/illustrations/Upload';
import Type from '@spectrum-icons/workflow/Type';
import Draw from '@spectrum-icons/workflow/Draw';
import UploadToCloud from '@spectrum-icons/workflow/UploadToCloud';
import Delete from '@spectrum-icons/workflow/Delete';
import './SignatureAdoptionDialog.css';

export const SignatureAdoptionDialog: FunctionComponent<{
  onAdopt?: () => void;
  onCancel?: () => void;
}> = ({ onAdopt, onCancel }) => {
  const dialog = useDialogContainer();

  const [name, setName] = useState<string>('');
  const [isValid] = useState<boolean>(false);

  let sigPad: SignatureCanvas | null = null;
  let clear = () => {
    sigPad?.clear();
  };

  // let trim = () => {
  //   this.setState({ trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL('image/png') });
  // };

  // @ts-ignore
  // @ts-ignore
  return (
    <Dialog>
      <Heading>Adopt a Signature</Heading>
      <Header>Create your personalized signature</Header>
      <Divider />
      <Content>
        <Tabs aria-label="Type, draw or upload your signature" disabledKeys={['upload']}>
          <TabList>
            <Item key="type">
              <Type aria-label="Type your signature" />
              <Text>Type</Text>
            </Item>
            <Item key="draw">
              <Draw aria-label="Draw your signature" />
              <Text>Draw</Text>
            </Item>
            <Item key="upload">
              <UploadToCloud aria-label="Upload your signature" />
              <Text>Upload</Text>
            </Item>
          </TabList>
          <TabPanels>
            <Item key="type">
              <Form>
                <Flex direction={{ base: 'column', M: 'row', L: 'row' }} gap="size-200">
                  <TextField
                    width="size-3000"
                    label="Name"
                    placeholder="John Doe"
                    autoFocus
                    value={name}
                    onChange={setName}
                  />
                  <TextField width="size-1000" label="Initials" placeholder="JD" />
                </Flex>
              </Form>

              {name.length > 0 && (
                <>
                  <Divider size="M" marginTop="size-200" marginBottom="size-200" />

                  <ActionGroup selectionMode="single" defaultSelectedKeys={['list']}>
                    <Item key="grid">
                      <Text>
                        <span style={{ fontFamily: "'Nothing You Could Do', cursive" }}>{name}</span>
                      </Text>
                    </Item>
                    <Item key="list">{name}</Item>
                    <Item key="gallery">{name}</Item>
                  </ActionGroup>

                  <Flex direction={{ base: 'column', M: 'row', L: 'row' }} gap="size-200">
                    <Image src="https://via.placeholder.com/250x100" alt="Name" />
                    <Image src="https://via.placeholder.com/100x100" alt="Initial" />
                    <MenuTrigger>
                      <ActionButton isQuiet>Change style</ActionButton>
                      <Menu
                        selectionMode="single"
                        // selectedKeys={selected}
                        // onSelectionChange={setSelected}
                      >
                        <Item key="left">Left</Item>
                        <Item key="middle">Middle</Item>
                        <Item key="right">Right</Item>
                      </Menu>
                    </MenuTrigger>
                  </Flex>
                </>
              )}
            </Item>
            <Item key="draw">
              <Well marginTop="size-100">
                <SignatureCanvas
                  ref={(ref) => {
                    sigPad = ref;
                  }}
                  penColor="black"
                  canvasProps={{ width: 560, height: 200, className: 'sigCanvas' }}
                />
                <div className="signature-modal-draw__line"></div>
                <ActionGroup density="compact" alignSelf="flex-end" onAction={() => clear()} isQuiet={true}>
                  <Item key="clear" aria-label="Clear signature">
                    <Delete aria-label="Delete icon" />
                    <Text>Clear</Text>
                  </Item>
                </ActionGroup>
              </Well>
            </Item>
            <Item key="upload">
              <IllustratedMessage>
                <Upload />
                <Heading>Drag and Drop your file</Heading>
                <Content>
                  Select a File from your computer
                  <br /> or create a new signature
                </Content>
              </IllustratedMessage>
            </Item>
          </TabPanels>
        </Tabs>
        <View
          marginTop="size-200"
          UNSAFE_style={{
            border:
              'var(--spectrum-alias-border-size-thick,var(--spectrum-global-dimension-static-size-25)) solid transparent',
          }}
        >
          <Text>
            By clicking Adopt Signature, I agree that the signature and initials will be the electronic representation
            of my signature and initials for electronically signing documents.
          </Text>
        </View>
      </Content>
      <ButtonGroup>
        <Button
          variant="secondary"
          onPress={() => {
            if (onCancel) {
              onCancel();
            }

            dialog.dismiss();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="cta"
          onPress={() => {
            if (onAdopt) {
              onAdopt();
            }

            dialog.dismiss();
          }}
          isDisabled={!isValid}
        >
          Adopt Signature
        </Button>
      </ButtonGroup>
    </Dialog>
  );
};
