import { FunctionComponent } from 'react';
import { SignatureRequestsTableView } from '../../components/SignatureRequestsTableView/SignatureRequestsTableView';
import { Button, View, Text, Flex } from '@adobe/react-spectrum';
import NewItem from '@spectrum-icons/workflow/NewItem';
import { useNavigate } from 'react-router-dom';

export const SignatureRequestsPage: FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <View marginEnd="size-200">
      <Flex direction="row" justifyContent="end">
        <Button variant="cta" alignSelf="end" onPress={() => navigate('/signature-requests/add')}>
          <NewItem aria-label="New signature request icon" />
          <Text>New signature request</Text>
        </Button>
      </Flex>

      <SignatureRequestsTableView></SignatureRequestsTableView>
    </View>
  );
};
