import React, { FunctionComponent, useEffect, useState } from 'react';
import { RenderPageProps } from '@zymlabs/react-pdf-viewer-core';

interface AnnotationPosition {
  top: number;
  left: number;
  height: number;
  width: number;
  transform?: string;
}

interface FieldAnnotationProps {
  position: AnnotationPosition;
  pageProps: RenderPageProps;
}

const FieldAnnotation: FunctionComponent<FieldAnnotationProps> = (props) => {
  const [position, setPosition] = useState<AnnotationPosition>();

  useEffect(() => {
    props.pageProps.doc.getPage(props.pageProps.pageIndex + 1).then((page) => {
      const viewPort = page
        .getViewport({
          scale: props.pageProps.scale,
          rotation: props.pageProps.rotation,
        })
        .clone({ dontFlip: true }); // Required for correct view port values

      setPosition({
        top: props.position.top,
        left: props.position.left,
        height: props.position.height,
        width: props.position.width,
        transform: `matrix(${viewPort.transform.join(',')})`,
      });
    });
  }, [props]);

  return (
    <>
      {position && (
        <>
          <div
            style={{
              position: 'absolute',
              top: `${position.top}px`,
              left: `${position.left}px`,
              height: `${position.height}px`,
              width: `${position.width}px`,
              transform: `${position.transform}`,
              transformOrigin: `-${position.left}px -${position.top}px`,
              background: 'blue',
              cursor: 'pointer',
              border: '1px dashed #dfbf00',
              lineHeight: `${position.height}px`,
              textAlign: 'center',
              opacity: '0.8',
              textTransform: 'uppercase',
              borderRadius: '2px',
              backgroundColor: '#ffe200',
              fontSize: '0.5rem',
            }}
          >
            Sign Here
          </div>
        </>
      )}
    </>
  );
};

FieldAnnotation.defaultProps = {};

export default FieldAnnotation;
