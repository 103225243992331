import { CSSProperties, FunctionComponent } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ListItemTypes } from './ListItemTypes';
import '@spectrum-css/icon/dist/index-vars.css';
import { DefaultExtensionType, defaultStyles, FileIcon } from 'react-file-icon';
import DragHandle from '@spectrum-icons/workflow/DragHandle';
import { ActionButton, Tooltip, TooltipTrigger } from '@adobe/react-spectrum';
import Delete from '@spectrum-icons/workflow/Delete';
import './ListItem.css';

interface SignerListItemProps {
  signerHashId: string;
  signerName: string;
  signerOrder: number;
  moveItem: (signerHashId: string, to: number) => void;
  findItem: (signerHashId: string) => { index: number };
  removeItem: (signerHashId: string) => void;
}

interface Item {
  signerHashId: string;
  originalIndex: number;
}

const SignerListItem: FunctionComponent<SignerListItemProps> = ({
  signerHashId,
  signerName,
  signerOrder,
  moveItem,
  findItem,
  removeItem,
}) => {
  const originalIndex = findItem(signerHashId).index;

  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: ListItemTypes.DOCUMENT,
      item: { signerHashId, originalIndex },

      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),

      end: (item, monitor) => {
        const { signerHashId: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveItem(droppedId, originalIndex);
        }
      },
    }),
    [signerHashId, originalIndex, moveItem],
  );

  const [, drop] = useDrop(
    () => ({
      accept: ListItemTypes.DOCUMENT,

      hover({ signerHashId: draggedId }: Item) {
        if (draggedId !== signerHashId) {
          const { index: overIndex } = findItem(signerHashId);
          moveItem(draggedId, overIndex);
        }
      },

      canDrop: () => false,
    }),
    [findItem, moveItem],
  );

  const handleRemoveItemPress = () => {
    removeItem(signerHashId);
  };

  const opacity = isDragging ? 0 : 1;
  const handleDragHandleStyle: CSSProperties = {
    cursor: 'move',
    display: 'inline-flex',
  };

  return (
    <li
      className="spectrum-AssetList-item"
      tabIndex={signerOrder}
      ref={(node) => dragPreview(drop(node))}
      style={{ opacity }}
    >
      <div ref={drag} style={handleDragHandleStyle}>
        <div className="spectrum-Icon spectrum-Icon--sizeXL">
          <DragHandle />
        </div>
      </div>

      <div className="spectrum-Icon spectrum-Icon--sizeXL">
        <FileIcon
          extension={signerName.slice(signerName.lastIndexOf('.') + 1)}
          {...defaultStyles[signerName.slice(signerName.lastIndexOf('.') + 1) as DefaultExtensionType]}
        />
      </div>

      <span className="spectrum-AssetList-itemLabel" style={{ minWidth: '100px' }}>
        {signerName}
      </span>

      <TooltipTrigger>
        <ActionButton aria-label="Remove signer" isQuiet justifySelf="end" onPress={handleRemoveItemPress}>
          <Delete />
        </ActionButton>
        <Tooltip>Remove signer</Tooltip>
      </TooltipTrigger>
    </li>
  );
};

SignerListItem.defaultProps = {};

export default SignerListItem;
