import React, { FunctionComponent, Key, useState } from 'react';
import { RenderPageProps, Viewer, Worker } from '@zymlabs/react-pdf-viewer-core';
// import '@zymlabs/react-pdf-viewer-core/lib/styles/index.css';
import '@zymlabs/react-pdf-viewer-default-layout/lib/styles/index.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import '../SignatureRequestViewer/ReactPdfViewer.css';

import { defaultLayoutPlugin } from '@zymlabs/react-pdf-viewer-default-layout';
import { ActionGroup, Grid, Item, View, Text } from '@adobe/react-spectrum';
import AnnotatePen from '@spectrum-icons/workflow/AnnotatePen';
import Date from '@spectrum-icons/workflow/Date';
import Select from '@spectrum-icons/workflow/Select';
import TextUnderline from '@spectrum-icons/workflow/TextUnderline';
import User from '@spectrum-icons/workflow/User';

import { Selection } from '@react-types/shared';
import PageSelectionLayer from './PageSelectionLayer';
import FieldAnnotation from './FieldAnnotation';

interface SignatureRequestFieldEditorProps {
  pdf: string | Uint8Array;
}

interface DocumentField {
  pageIndex: number;
  fieldType: string;
  fieldName: string;
  required: boolean;
  top: number;
  left: number;
  width: number;
  height: number;
}

const SignatureRequestFieldEditor: FunctionComponent<SignatureRequestFieldEditorProps> = ({ pdf }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {},
  });

  const [selection, setSelection] = useState<Set<Key>>(new Set(['hand']));
  const [fields, setFields] = useState<DocumentField[]>([]);

  const renderPage = (props: RenderPageProps) => {
    console.log(fields);
    return (
      <>
        {props.canvasLayer.children}
        {props.textLayer.children}
        {props.annotationLayer.children}
        {fields.length > 0 &&
          fields.map((field) => (
            <>
              {field.pageIndex === props.pageIndex && (
                <FieldAnnotation
                  position={{ top: field.top, left: field.left, width: field.width, height: field.height }}
                  pageProps={props}
                />
              )}
            </>
          ))}

        {selection.has('signature') && (
          <PageSelectionLayer
            pageProps={props}
            onDragEnd={(e) => {
              // Enforce minimum area
              let area = e.width * e.height;
              if (area < 100) {
                return;
              }

              setFields([
                ...fields,
                {
                  pageIndex: props.pageIndex,
                  fieldType: '',
                  fieldName: 'signature',
                  required: true,
                  top: e.top,
                  left: e.left,
                  width: e.width,
                  height: e.height,
                },
              ]);
            }}
          />
        )}
      </>
    );
  };

  return (
    <Grid areas={['content sidebar']} columns={['8fr', '16px']} rows={['auto']} height="100vh" gap="size-100">
      <View backgroundColor="gray-800" gridArea="content" overflow="auto">
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.9.359/build/pdf.worker.js">
          <Viewer fileUrl={pdf} plugins={[defaultLayoutPluginInstance]} renderPage={renderPage} />
        </Worker>
      </View>

      <View gridArea="sidebar" marginEnd="size-100">
        <ActionGroup
          overflowMode="collapse"
          orientation="vertical"
          buttonLabelBehavior="collapse"
          isQuiet
          maxHeight={600}
          selectionMode="single"
          defaultSelectedKeys={['pointer']}
          onSelectionChange={(key: Selection) => {
            if (key === 'all') {
              return;
            }

            setSelection(key);
          }}
          isJustified
          isEmphasized
        >
          <Item key="pointer" textValue="Pointer">
            <Select />
            <Text>Pointer</Text>
          </Item>
          <Item key="name" textValue="Name">
            <User />
            <Text>Name</Text>
          </Item>
          <Item key="signature" textValue="Signature">
            <AnnotatePen />
            <Text>Signature</Text>
          </Item>
          <Item key="date" textValue="Date">
            <Date />
            <Text>Date</Text>
          </Item>
          <Item key="text" textValue="Text">
            <TextUnderline />
            <Text>Text</Text>
          </Item>
        </ActionGroup>
      </View>
    </Grid>
  );
};

SignatureRequestFieldEditor.defaultProps = {};

export default SignatureRequestFieldEditor;
