import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import Selecto from 'react-selecto';
import { RenderPageProps } from '@zymlabs/react-pdf-viewer-core';
import { fromString, inverse, toString, Matrix, Point, applyToPoint } from 'transformation-matrix';

interface PosBox {
  top: number;
  left: number;
  width: number;
  height: number;
}

interface PageSelectionLayerProps {
  pageProps: RenderPageProps;
  onDragEnd: (p: PosBox) => any;
}

const PageSelectionLayer: FunctionComponent<PageSelectionLayerProps> = ({ pageProps, onDragEnd }) => {
  let selectionContainer = useRef(null);

  const [inverseMatrix, setInverseMatrix] = useState<Matrix>();

  useEffect(() => {
    pageProps.doc.getPage(pageProps.pageIndex + 1).then((page) => {
      const viewPort = page
        .getViewport({
          scale: pageProps.scale,
          rotation: pageProps.rotation,
        })
        .clone({ dontFlip: true }); // Required for correct view port values

      console.log(`matrix(${viewPort.transform.join(',')})`);
      console.log(fromString(`matrix(${viewPort.transform.join(',')})`));
      console.log(inverse(fromString(`matrix(${viewPort.transform.join(',')})`)));
      console.log(toString(inverse(fromString(`matrix(${viewPort.transform.join(',')})`))));

      // We need the inverse matrix in order to adjust the rotation and scale from the PDF viewer to the scaled/rotated
      // view port of the browser's selecto rect outputs.
      setInverseMatrix(inverse(fromString(`matrix(${viewPort.transform.join(',')})`)));
    });
  }, [pageProps]);

  return (
    <>
      <div
        ref={selectionContainer}
        className="selection-layer"
        style={{
          height: '100%',
          width: '100%',
          position: 'absolute',
        }}
      >
        <Selecto
          // The container to add a selection element
          container={document.body}
          // The area to drag selection element (default: container)
          // dragContainer={'.selection-layer'}
          // Targets to select. You can register a queryselector or an Element.
          selectableTargets={[]}
          // Whether to select by click (default: true)
          selectByClick={true}
          // Whether to select from the target inside (default: true)
          selectFromInside={true}
          // After the select, whether to select the next target with the selected target (deselected if the target is selected again).
          continueSelect={false}
          // Determines which key to continue selecting the next target via keydown and keyup.
          toggleContinueSelect={'shift'}
          // The container for keydown and keyup events
          keyContainer={window}
          // The rate at which the target overlaps the drag area to be selected. (default: 100)
          hitRate={100}
          onSelect={(e) => {
            e.added.forEach((el) => {
              el.classList.add('selected');
            });
            e.removed.forEach((el) => {
              el.classList.remove('selected');
            });
          }}
          onDragEnd={(e) => {
            // Inverse matrix not ready, let just return
            if (!inverseMatrix) {
              return;
            }

            // We need to get the page container position relative the screen because the position returned back by the
            // DragEnd event is relative to the viewport. We will have to offset it to get to the position relative to
            // the page.
            // @ts-ignore
            let clientRect = selectionContainer.current.getBoundingClientRect();

            // Since the PDF viewer can be rotated and zoomed, when drawing the rectangle, we need to inverse the transformation
            // and then apply the transformation matrix to the box to get to what the points would be for 1.0 scale without rotation.
            //
            // When the viewer displays the annotations again, it will apply the scale and rotation transforms.
            //
            // Get the top left and bottom right points of the box relative to the page.
            let topLeftPoint: Point = { x: e.rect.left - clientRect.left, y: e.rect.top - clientRect.top };
            let bottomRightPoint: Point = { x: topLeftPoint.x + e.rect.width, y: topLeftPoint.y + e.rect.height };

            let top = applyToPoint(inverseMatrix, topLeftPoint).y;
            let left = applyToPoint(inverseMatrix, topLeftPoint).x;
            let width = applyToPoint(inverseMatrix, bottomRightPoint).x - applyToPoint(inverseMatrix, topLeftPoint).x;
            let height = applyToPoint(inverseMatrix, bottomRightPoint).y - applyToPoint(inverseMatrix, topLeftPoint).y;

            // TODO: This calculation for inverse matrix doesn't take into account drags and the PDF is rotated. It
            // currently only works when it is scaled.

            onDragEnd({
              top: top,
              left: left,
              width: width,
              height: height,
            });
          }}
        />
      </div>
    </>
  );
};

PageSelectionLayer.defaultProps = {};

export default PageSelectionLayer;
