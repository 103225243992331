import React, { FunctionComponent, useState } from 'react';
import { useMediaQuery } from '@react-spectrum/utils';

import '@spectrum-web-components/top-nav/sp-top-nav.js';
import '@spectrum-web-components/top-nav/sp-top-nav-item.js';
import '@spectrum-web-components/action-menu/sp-action-menu.js';
import '@spectrum-web-components/menu/sp-menu-item.js';
import '@spectrum-web-components/menu/sp-menu-divider.js';

import { Worker, Viewer, RenderPageProps, RenderPage } from '@zymlabs/react-pdf-viewer-core';
// import '@zymlabs/react-pdf-viewer-core/lib/styles/index.css';
import '@zymlabs/react-pdf-viewer-default-layout/lib/styles/index.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import './ReactPdfViewer.css';

import { defaultLayoutPlugin } from '@zymlabs/react-pdf-viewer-default-layout';

import { SignatureESignDisclosureAndConsentDialog } from '../SignatureESignDisclosureAndConsentDialog/SignatureESignDisclosureAndConsentDialog';
import { DialogContainer } from '@adobe/react-spectrum';
import { SignatureAnnotation } from '../SignatureAnnotation/SignatureAnnotation';

interface SignatureRequestViewerProps {
  pdf: string;
  eSignDisclosureAndConsentAgreed?: boolean;
}
//
// interface SignatureRequest {
//   requestTitle: string;
//   requestStatus: string;
//   documents: [];
// }

// interface SignatureRequestDocument {
//
// }

const SignatureRequestViewer: FunctionComponent<SignatureRequestViewerProps> = ({
  pdf,
  eSignDisclosureAndConsentAgreed,
}) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {},
  });

  const [eSignDisclosureAndConsentDialogOpened, setESignDisclosureAndConsentDialogOpened] = useState<boolean>(
    !eSignDisclosureAndConsentAgreed,
  );
  // const [esignDisclosureAndConsentAgreed, setESignDisclosureAndConsentAgreed] = useState<boolean>(
  //   eSignDisclosureAndConsentAgreed,
  // );

  let isMobile = useMediaQuery('(max-width: 1024px)');

  const renderPage: RenderPage = (props: RenderPageProps) => {
    return (
      <>
        {props.canvasLayer.children}
        {props.textLayer.children}
        {props.annotationLayer.children}

        <SignatureAnnotation rect={[59.1541, 612.418, 249.707, 629.791]} props={props} />
      </>
    );
  };

  return (
    <div
      className="App spectrum spectrum--medium spectrum--dark"
      style={{
        background: 'var( --spectrum-global-color-gray-800)',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      <div style={{ overflow: 'auto' }}>
        {/* <Document
                file="29cbd16b-c1c6-4db0-9417-b0117ddbb8cb.pdf"
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Outline></Outline>
                {Array.from(
                  new Array(numPages),
                  (el, index) => (
                    <div key={`page_${index + 1}`} style={{ margin: '10px' }}>
                      <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        renderAnnotationLayer={true} renderInteractiveForms={true}

                      />
                    </div>
                  ),
                )}
            </Document> */}
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.9.359/build/pdf.worker.js">
          <Viewer fileUrl={pdf} plugins={[defaultLayoutPluginInstance]} renderPage={renderPage} />
        </Worker>

        <DialogContainer
          onDismiss={() => setESignDisclosureAndConsentDialogOpened(false)}
          type={isMobile ? 'fullscreenTakeover' : 'modal'}
        >
          {eSignDisclosureAndConsentDialogOpened && <SignatureESignDisclosureAndConsentDialog />}
        </DialogContainer>
      </div>
    </div>
  );
};

SignatureRequestViewer.defaultProps = {
  eSignDisclosureAndConsentAgreed: false,
};

export default SignatureRequestViewer;
