import React, { useState } from 'react';

import '@spectrum-css/vars/dist/spectrum-global.css';
import '@spectrum-css/vars/dist/spectrum-large.css';
import '@spectrum-css/vars/dist/spectrum-medium.css';
import '@spectrum-css/vars/dist/spectrum-light.css';
import '@spectrum-css/vars/dist/spectrum-dark.css';
import '@spectrum-css/page/dist/index-vars.css';

import '@spectrum-css/vars/dist/components/spectrum-tabs.css';
import '@spectrum-css/tabs/dist/index-vars.css';
import '@spectrum-css/menu/dist/index-vars.css';
import '@spectrum-css/actionmenu/dist/index-vars.css';
import '@spectrum-web-components/theme/sp-theme.js';
import '@spectrum-web-components/top-nav/sp-top-nav.js';
import '@spectrum-web-components/top-nav/sp-top-nav-item.js';
import '@spectrum-web-components/action-menu/sp-action-menu.js';
import '@spectrum-web-components/menu/sp-menu-item.js';
import '@spectrum-web-components/menu/sp-menu-divider.js';

import './TopNav.css';
import {
  ActionButton,
  defaultTheme,
  Flex,
  Header,
  Item,
  Menu,
  MenuTrigger,
  Provider,
  TabList,
  Tabs,
  Text,
  ToggleButton,
  Tooltip,
  TooltipTrigger,
} from '@adobe/react-spectrum';
//
// import { wrapWc } from 'wc-react';

import { Avatar } from '@react-spectrum/avatar';
import LogOut from '@spectrum-icons/workflow/LogOut';
import Contrast from '@spectrum-icons/workflow/Contrast';
import { useSpectrumProvider } from '../../contexts/SpectrumProviderContext';
import { useKeycloak } from '@react-keycloak/web';

// const SpTheme = wrapWc('sp-theme');
// const SpTopNav = wrapWc('sp-top-nav');
// const SpTopNavItem = wrapWc('sp-top-nav-item');
// const SpActionMenu = wrapWc('sp-action-menu');
// const SpMenuItem = wrapWc('sp-menu-item');
// const SpMenuDivider = wrapWc('sp-menu-divider');

function TopNav() {
  const { keycloak } = useKeycloak();
  const [fullName, setFullName] = useState('User');

  keycloak
    .loadUserProfile()
    .then(function (profile) {
      setFullName(profile.firstName + ' ' + profile.lastName);
    })
    .catch(function () {
      alert('Failed to load user profile');
    });

  const darkModeContext = useSpectrumProvider();
  const setDarkMode = (enabled: boolean) => {
    darkModeContext.setColorScheme(enabled ? 'dark' : 'light');
  };

  return (
    <Provider theme={defaultTheme} colorScheme="dark">
      <Header>
        <Flex direction="row" gap="size-100">
          <img className="logo" src="/images/logo.svg" alt="Logo" />
          <Tabs aria-label="History of Ancient Rome" isQuiet>
            <TabList>
              <Item key="Emp">Images</Item>
              <Item key="MaR">PDF</Item>
              <Item key="FoR">Signatures</Item>
            </TabList>
          </Tabs>
          <TooltipTrigger>
            <ToggleButton
              height="size-600"
              minWidth="size-600"
              isQuiet
              isSelected={darkModeContext.colorScheme === 'dark'}
              onChange={setDarkMode}
            >
              <Contrast aria-label="Dark mode icon" />
            </ToggleButton>
            <Tooltip>Toggle dark mode</Tooltip>
          </TooltipTrigger>
          <MenuTrigger align="end" direction="bottom">
            <ActionButton height="size-600" minWidth="size-2000" isQuiet>
              <Avatar src="/images/logo.svg" size="avatar-size-700" />
              <Text>{fullName}</Text>
            </ActionButton>
            <Menu>
              <Item>Profile</Item>
              <Item>Organization</Item>
              <Item>Billing</Item>

              <Item>
                <LogOut aria-label="Logout icon" />
                <Text>Logout</Text>
              </Item>
            </Menu>
          </MenuTrigger>
        </Flex>
      </Header>
    </Provider>
  );
}

export default TopNav;
