import {
  Cell,
  Column,
  Content,
  Heading,
  IllustratedMessage,
  Row,
  StatusLight,
  TableBody,
  TableHeader,
  TableView,
} from '@adobe/react-spectrum';
import { FunctionComponent, useEffect } from 'react';
import NotFound from '@spectrum-icons/illustrations/NotFound';
import { useAsyncList } from '@react-stately/data';
import { useDocumentServices } from '../../contexts/DocumentServicesApiContext';
import { useAuth } from '../../contexts/AuthContext';
import { GetSignatureRequestListResponse, SignatureRequestStatusType } from '@zymlabs/document-services-axios-sdk';
import { Link as RouterLink } from 'react-router-dom';

export const SignatureRequestsTableView: FunctionComponent<{
  onAdopt?: () => void;
  onCancel?: () => void;
}> = ({ onAdopt, onCancel }) => {
  const renderEmptyState = () => (
    <IllustratedMessage>
      <NotFound />
      <Heading>No signature requests</Heading>
      <Content>Create your first signature request</Content>
    </IllustratedMessage>
  );

  const { signaturesApi } = useDocumentServices();

  let list = useAsyncList({
    async load({ signal, sortDescriptor }) {
      let sorts = '-createdAt';

      if (sortDescriptor) {
        sorts = (sortDescriptor.direction === 'descending' ? '-' : '') + sortDescriptor.column;
      }

      let response = await signaturesApi.signatureRequestsGetList(undefined, undefined, sorts);
      console.log(sortDescriptor);
      return {
        items: response.data.items,
        sortDescriptor: sortDescriptor,
      };
    },
  });

  const { token } = useAuth();

  useEffect(() => {
    list.reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const requestStatusVariant = (
    requestStatus: SignatureRequestStatusType,
  ):
    | 'positive'
    | 'negative'
    | 'notice'
    | 'info'
    | 'neutral'
    | 'celery'
    | 'chartreuse'
    | 'yellow'
    | 'magenta'
    | 'fuchsia'
    | 'purple'
    | 'indigo'
    | 'seafoam' => {
    let statusVariant:
      | 'positive'
      | 'negative'
      | 'notice'
      | 'info'
      | 'neutral'
      | 'celery'
      | 'chartreuse'
      | 'yellow'
      | 'magenta'
      | 'fuchsia'
      | 'purple'
      | 'indigo'
      | 'seafoam' = 'neutral';

    switch (requestStatus) {
      case SignatureRequestStatusType.Draft:
      case SignatureRequestStatusType.Deleted:
        statusVariant = 'neutral';
        break;

      case SignatureRequestStatusType.Completed:
        statusVariant = 'positive';
        break;

      case SignatureRequestStatusType.Declined:
        statusVariant = 'negative';
        break;

      case SignatureRequestStatusType.Requested:
        statusVariant = 'notice';
    }

    return statusVariant;
  };

  return (
    <TableView
      aria-label="List of signature requests"
      selectionMode="none"
      overflowMode="wrap"
      height="100vh"
      renderEmptyState={renderEmptyState}
      sortDescriptor={list.sortDescriptor}
      onSortChange={list.sort}
    >
      <TableHeader>
        <Column key="hashId" allowsSorting={false}>
          Id
        </Column>
        <Column key="requestTitle" allowsSorting>
          Title
        </Column>
        <Column key="requestStatus" allowsSorting>
          Status
        </Column>
        <Column key="createdAt" allowsSorting align="end">
          Created
        </Column>
      </TableHeader>
      <TableBody
        items={list.items as GetSignatureRequestListResponse[]}
        loadingState={list.loadingState}
        onLoadMore={list.loadMore}
      >
        {(item: GetSignatureRequestListResponse) => (
          <Row key={item.hashId}>
            <Cell>
              <RouterLink to={{ pathname: '/signature-requests/' + item.hashId }}>{item.hashId}</RouterLink>
            </Cell>
            <Cell>{item.requestTitle}</Cell>
            <Cell>
              <StatusLight variant={requestStatusVariant(item.requestStatus)}>{item.requestStatus}</StatusLight>
            </Cell>
            <Cell>{item.createdAt}</Cell>
          </Row>
        )}
      </TableBody>
    </TableView>
  );
};
