import { useKeycloak } from '@react-keycloak/web';
import { createContext, useContext, useEffect, useState } from 'react';

interface AuthInterface {
  isLoggedIn: boolean;
  token: string | null;
  setToken: (token: string) => void;
  login: () => void;
  logout: () => void;
}

const AuthContext = createContext<AuthInterface>({
  isLoggedIn: false,
  token: null,
  setToken: (token: string) => null,
  login: () => null,
  logout: () => null,
});

export const AuthProvider = ({ children }: any) => {
  const { keycloak } = useKeycloak();
  const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(window.localStorage.getItem('token') ?? null);

  useEffect(() => {
    if (keycloak.authenticated) {
      setToken(keycloak.token || null);
    }
  }, [keycloak]);

  useEffect(() => {
    if (token) {
      window.localStorage.setItem('token', token);
    }

    setLoggedIn(!!token);
  }, [token]);

  const login = () => {
    if (!keycloak.authenticated) {
      keycloak.login();
    }
  };

  const logout = () => {
    if (keycloak.authenticated) {
      keycloak.logout();
    }
    window.localStorage.removeItem('token');
    setToken(null);
  };

  return <AuthContext.Provider value={{ isLoggedIn, token, setToken, login, logout }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
