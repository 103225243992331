import { createContext, useContext, useState } from 'react';
import { defaultTheme, Provider } from '@adobe/react-spectrum';

type SpectrumColorScheme = 'light' | 'dark';

interface SpectrumProviderInterface {
  colorScheme: SpectrumColorScheme | undefined;
  setColorScheme: (colorScheme: SpectrumColorScheme) => void;
}

const SpectrumProviderContext = createContext<SpectrumProviderInterface>({
  colorScheme: 'light',
  setColorScheme: (colorScheme) => null,
});

export const SpectrumProvider = ({ children }: any) => {
  const [colorScheme, setColorScheme] = useState<SpectrumColorScheme | undefined>();

  return (
    <SpectrumProviderContext.Provider value={{ colorScheme, setColorScheme }}>
      <Provider theme={defaultTheme} colorScheme={colorScheme}>
        {children}
      </Provider>
    </SpectrumProviderContext.Provider>
  );
};

export const useSpectrumProvider = () => useContext(SpectrumProviderContext);
