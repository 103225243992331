import { FunctionComponent } from 'react';

export const NotFound: FunctionComponent = () => {
  return (
    <div className="loading-screen">
      <div className="loader">
        <img src="/images/logo.png" height="128" alt="Loading icon" />
        <div className="message">Page not found</div>
      </div>
    </div>
  );
};
