import { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { Configuration, PDFApi, SignaturesApi } from '@zymlabs/document-services-axios-sdk';
import env from '@beam-australia/react-env';

interface DocumentServicesApiInterface {
  pdfApi: PDFApi;
  signaturesApi: SignaturesApi;
}

const DocumentServicesApiContext = createContext<DocumentServicesApiInterface>({
  pdfApi: new PDFApi(),
  signaturesApi: new SignaturesApi(),
});

const DocumentServicesApiProvider = ({ children }: any) => {
  const { token } = useAuth();

  const [pdfApi, setPdfApi] = useState(new PDFApi());
  const [signaturesApi, setSignaturesApi] = useState(new SignaturesApi());

  useEffect(() => {
    if (token) {
      const configuration = new Configuration({
        basePath: env('DOCUMENT_SERVICES_API_URL'),
        accessToken: token,
      });

      setPdfApi(new PDFApi(configuration));
      setSignaturesApi(new SignaturesApi(configuration));
    }
  }, [token]);

  return (
    <DocumentServicesApiContext.Provider
      value={{
        pdfApi,
        signaturesApi,
      }}
    >
      {children}
    </DocumentServicesApiContext.Provider>
  );
};

export default DocumentServicesApiProvider;

export const useDocumentServices = () => useContext(DocumentServicesApiContext);
