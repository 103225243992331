import React, { FunctionComponent, useEffect, useState } from 'react';
import { RenderPageProps } from '@zymlabs/react-pdf-viewer-core';
import { DialogContainer } from '@adobe/react-spectrum';
import { SignatureAdoptionDialog } from '../SignatureAdoptionDialog/SignatureAdoptionDialog';
import { useMediaQuery } from '@react-spectrum/utils';

interface SignatureAnnotationPosition {
  top: number;
  left: number;
  height: number;
  width: number;
  transform: string;
}

export const SignatureAnnotation: FunctionComponent<{ rect: number[]; props: RenderPageProps }> = ({ rect, props }) => {
  const [position, setPosition] = useState<SignatureAnnotationPosition>();
  const [opened, setOpened] = useState<boolean>();
  let isMobile = useMediaQuery('(max-width: 1024px)');

  useEffect(() => {
    props.doc.getPage(props.pageIndex + 1).then((page) => {
      const viewPort = page
        .getViewport({
          scale: props.scale,
          rotation: props.rotation,
        })
        .clone({ dontFlip: true }); // Required for correct view port values

      // We need to take the PDF coordinates which start from bottom left X/Y and convert it to HTML coordinates which
      // are top left X/Y. PDF annotations are positioned using a rectangle X/Y for the top left of the rect to the bottom right of the rect.

      // [left, top, right, bottom]
      const normalizeRect = (r: number[]): number[] => [
        Math.min(r[0], r[2]),
        Math.min(r[1], r[3]),
        Math.max(r[0], r[2]),
        Math.max(r[1], r[3]),
      ];

      const normalizedRectValues = normalizeRect([
        rect[0],
        viewPort.viewBox[3] - rect[1] + viewPort.viewBox[1],
        rect[2],
        viewPort.viewBox[3] - rect[3] + viewPort.viewBox[1],
      ]);

      const width = normalizedRectValues[2] - normalizedRectValues[0];
      const height = normalizedRectValues[3] - normalizedRectValues[1];

      setPosition({
        top: normalizedRectValues[1],
        left: normalizedRectValues[0],
        height: height,
        width: width,
        transform: `matrix(${viewPort.transform.join(',')})`,
      });
    });
  }, [props, rect]);

  return (
    <>
      {position && (
        <>
          <div
            onClick={() => setOpened(true)}
            style={{
              position: 'absolute',
              top: `${position.top}px`,
              left: `${position.left}px`,
              height: `${position.height}px`,
              width: `${position.width}px`,
              transform: `${position.transform}`,
              transformOrigin: `-${position.left}px -${position.top}px`,
              background: 'blue',
              cursor: 'pointer',
              border: '1px dashed #dfbf00',
              lineHeight: `${position.height}px`,
              textAlign: 'center',
              opacity: '0.8',
              textTransform: 'uppercase',
              borderRadius: '2px',
              backgroundColor: '#ffe200',
              fontSize: '0.5rem',
            }}
          >
            Sign Here
          </div>
          <div className="spectrum-CoachMarkIndicator" style={{ display: 'inline-block' }}>
            <div className="spectrum-CoachMarkIndicator-ring"></div>
            <div className="spectrum-CoachMarkIndicator-ring"></div>
            <div className="spectrum-CoachMarkIndicator-ring"></div>
          </div>
          <DialogContainer onDismiss={() => setOpened(false)} type={isMobile ? 'fullscreenTakeover' : 'modal'}>
            {opened && <SignatureAdoptionDialog />}
          </DialogContainer>
        </>
      )}
    </>
  );
};
