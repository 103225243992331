import { useKeycloak } from '@react-keycloak/web';
import { ComponentType } from 'react';
import { BrowserRouter, Route, Routes as BrowserRoutes } from 'react-router-dom';
import { AuthLayout, DashboardLayout } from '../layouts';
import { dashboard as dashboardRoutes, RouteDefinition } from './index';
import { NotFound } from '../pages/Errors';

const childRoutes = (Layout: ComponentType, routes: RouteDefinition[]) =>
  routes.map(({ children, path, component: Component }, index) =>
    children
      ? // Route item with children
        children.map(({ path: childPath, component: ChildComponent }, childIndex) => (
          <Route
            key={childIndex}
            path={childPath}
            element={
              <Layout>
                <ChildComponent />
              </Layout>
            }
          />
        ))
      : Component && (
          // Route item without children
          <Route
            key={index}
            path={path}
            element={
              <Layout>
                <Component />
              </Layout>
            }
          />
        ),
  );

const Routes = () => {
  const { initialized } = useKeycloak();

  if (!initialized) {
    return <div>Loading...</div>;
  }

  return (
    <BrowserRouter>
      <BrowserRoutes>
        {childRoutes(DashboardLayout, dashboardRoutes)}
        {/*{childRoutes(AuthLayout, authRoutes)}*/}
        <Route path="*" element={<NotFound></NotFound>}>
          <AuthLayout>
            <NotFound />
          </AuthLayout>
        </Route>
      </BrowserRoutes>
    </BrowserRouter>
  );
};

export default Routes;
