import { FunctionComponent } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { Heading } from '@adobe/react-spectrum';
import WizardStepActionBar from '../WizardStepActionBar';

const SendStep: FunctionComponent<StepWizardChildProps | any> = (props) => {
  return (
    <>
      <Heading level={3}>Review and send signature request</Heading>
      <WizardStepActionBar {...props} />
    </>
  );
};

SendStep.defaultProps = {};

export default SendStep;
