import { ComponentType, ReactElement } from 'react';
import { SignatureRequestsPage } from '../pages/SignatureRequests';
import { SignatureRequestsNewPage } from '../pages/SignatureRequests/New';
import { SignatureRequestsViewPage } from '../pages/SignatureRequests/View';

interface ChildRouteDefinition {
  path: string;
  name: string;
  component: ComponentType<any>;
  hidden?: boolean;
  badge?: string;
}

export interface RouteDefinition {
  id: string;
  path: string;
  header?: string;
  name?: string;
  icon?: ReactElement;
  containsHome?: boolean;
  component?: ComponentType<any>;
  children: ChildRouteDefinition[] | null;
  open?: boolean;
  badge?: string;
}

const signatureRoutes = {
  id: 'Signature Requests',
  path: '/signature-requests',
  children: [
    {
      path: '/signature-requests',
      name: 'Signature Request',
      component: SignatureRequestsPage,
    },
    {
      path: '/signature-requests/add',
      name: 'New Signature Request',
      component: SignatureRequestsNewPage,
    },
    {
      path: '/signature-requests/:signatureRequestHashId',
      name: 'New Signature Request',
      component: SignatureRequestsViewPage,
    },
    {
      path: '/signature-requests/:signatureRequestHashId/edit',
      name: 'New Signature Request',
      component: SignatureRequestsNewPage,
    },
  ],
};

export const dashboard = [signatureRoutes];
const routes: RouteDefinition[] = [signatureRoutes];

export default routes;
