import TopNav from '../components/TopNav/TopNav';
import '@spectrum-css/sidenav/dist/index-vars.css';
import { Grid, View } from '@adobe/react-spectrum';
import { Link as RouterLink } from 'react-router-dom';
interface Props {
  children: any;
  routes: any;
}

const DashboardComponent = ({ children, routes }: Props) => {
  return (
    <div
      className="App spectrum spectrum--medium"
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      <div style={{ overflow: 'auto' }}>
        <Grid
          areas={['header  header', 'sidebar content', 'footer  footer']}
          columns={['1fr', '3fr']}
          rows={['size-1000', 'auto', 'size-1000']}
          gap="size-100"
        >
          <View gridArea="header">
            <TopNav />
          </View>
          <View gridArea="sidebar">
            <nav>
              <ul className="spectrum-SideNav">
                <li className="spectrum-SideNav-item is-selected">
                  <RouterLink to="/signature-requests" className="spectrum-SideNav-itemLink" aria-current="page">
                    Requests
                  </RouterLink>
                </li>
              </ul>
            </nav>
          </View>
          <View gridArea="content">{children}</View>
          <View gridArea="footer"></View>
        </Grid>
      </div>
    </div>
  );
};

export const DashboardLayout: any = DashboardComponent;
