import React, { FunctionComponent } from 'react';
import { DndProvider } from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import List from './List';
import { Flex } from '@adobe/react-spectrum';

interface ListContainerItem {
  signerHashId: string;
  signerName: string;
  signerOrder: number;
}

interface ListContainerProps {
  items: Array<ListContainerItem>;

  onMoveItem?: (signerHashId: string, signerOrder: number, items: Array<ListContainerItem>) => void;
  onRemoveItem?: (signerHashId: string) => void;
}

const ListContainer: FunctionComponent<ListContainerProps> = ({ items, onMoveItem, onRemoveItem }) => {
  return (
    <Flex direction="row">
      <DndProvider options={HTML5toTouch}>
        <List items={items} onMoveItem={onMoveItem} onRemoveItem={onRemoveItem} />
      </DndProvider>
    </Flex>
  );
};

ListContainer.defaultProps = {};

export default ListContainer;
