import { CSSProperties, FunctionComponent } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ListItemTypes } from './ListItemTypes';
import '@spectrum-css/icon/dist/index-vars.css';
import { DefaultExtensionType, defaultStyles, FileIcon } from 'react-file-icon';
import DragHandle from '@spectrum-icons/workflow/DragHandle';
import { ActionButton, Tooltip, TooltipTrigger } from '@adobe/react-spectrum';
import Delete from '@spectrum-icons/workflow/Delete';
import './ListItem.css';

interface DocumentListItemProps {
  documentHashId: string;
  fileName: string;
  documentOrder: number;
  moveItem: (documentHashId: string, to: number) => void;
  findItem: (documentHashId: string) => { index: number };
  removeItem: (documentHashId: string) => void;
}

interface Item {
  documentHashId: string;
  originalIndex: number;
}

const DocumentListItem: FunctionComponent<DocumentListItemProps> = ({
  documentHashId,
  fileName,
  documentOrder,
  moveItem,
  findItem,
  removeItem,
}) => {
  const originalIndex = findItem(documentHashId).index;

  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: ListItemTypes.DOCUMENT,
      item: { documentHashId, originalIndex },

      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),

      end: (item, monitor) => {
        const { documentHashId: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveItem(droppedId, originalIndex);
        }
      },
    }),
    [documentHashId, originalIndex, moveItem],
  );

  const [, drop] = useDrop(
    () => ({
      accept: ListItemTypes.DOCUMENT,

      hover({ documentHashId: draggedId }: Item) {
        if (draggedId !== documentHashId) {
          const { index: overIndex } = findItem(documentHashId);
          moveItem(draggedId, overIndex);
        }
      },

      canDrop: () => false,
    }),
    [findItem, moveItem],
  );

  const handleRemoveItemPress = () => {
    removeItem(documentHashId);
  };

  const opacity = isDragging ? 0 : 1;
  const handleDragHandleStyle: CSSProperties = {
    cursor: 'move',
    display: 'inline-flex',
  };

  return (
    <li
      className="spectrum-AssetList-item"
      tabIndex={documentOrder}
      ref={(node) => dragPreview(drop(node))}
      style={{ opacity }}
    >
      <div ref={drag} style={handleDragHandleStyle}>
        <div className="spectrum-Icon spectrum-Icon--sizeXL">
          <DragHandle />
        </div>
      </div>

      <div className="spectrum-Icon spectrum-Icon--sizeXL">
        <FileIcon
          extension={fileName.slice(fileName.lastIndexOf('.') + 1)}
          {...defaultStyles[fileName.slice(fileName.lastIndexOf('.') + 1) as DefaultExtensionType]}
        />
      </div>

      <span className="spectrum-AssetList-itemLabel" style={{ minWidth: '100px' }}>
        {fileName}
      </span>

      <TooltipTrigger>
        <ActionButton aria-label="Remove document" isQuiet justifySelf="end" onPress={handleRemoveItemPress}>
          <Delete />
        </ActionButton>
        <Tooltip>Remove document</Tooltip>
      </TooltipTrigger>
    </li>
  );
};

DocumentListItem.defaultProps = {};

export default DocumentListItem;
