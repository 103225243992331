import { FunctionComponent, useEffect, useState } from 'react';
import WizardStepActionBar from '../WizardStepActionBar';
import SignatureRequestFieldEditor from '../../SignatureRequestFieldEditor/SignatureRequestFieldEditor';
import { useDocumentServices } from '../../../contexts/DocumentServicesApiContext';
import { ProgressCircle } from '@adobe/react-spectrum';

interface AddFieldsStepProps {
  signatureRequestHashId: string | undefined;
}

const AddFieldsStep: FunctionComponent<AddFieldsStepProps> = ({ signatureRequestHashId }) => {
  const { signaturesApi } = useDocumentServices();
  const [pdf, setPdf] = useState<Uint8Array>(new Uint8Array());

  useEffect(() => {
    const fetchSignatureRequestDocuments = async (signatureRequestHashId: string) => {
      let signatureRequestDocumentsResponse = await signaturesApi.signatureRequestDocumentsGetCombinedBlob(
        signatureRequestHashId,
        { responseType: 'arraybuffer' },
      );

      setPdf(signatureRequestDocumentsResponse.data);
    };

    if (signatureRequestHashId) {
      fetchSignatureRequestDocuments(signatureRequestHashId);
    }
  }, [signatureRequestHashId, signaturesApi]);

  return (
    <>
      {!pdf && <ProgressCircle aria-label="Loading…" size="L" justifySelf="center" isIndeterminate />}
      {pdf && <SignatureRequestFieldEditor pdf={pdf} />}

      <WizardStepActionBar />
    </>
  );
};

AddFieldsStep.defaultProps = {};

export default AddFieldsStep;
